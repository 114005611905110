/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useContext, useEffect, useRef, useState, useCallback } from 'react';
import cx from 'classnames';
import { useRouter } from 'next/router';
import { Backdrop } from '../Backdrop/Backdrop';
import Hidden from '../Hidden';
import { Label } from '@/features/i18n';
import { useLocale } from '@/hooks/useLocale';
import { countryCodes } from '@/constants/locale';
import ProgressModal from '../AZCustomComponent/ProgressModal';
import { eventConstants } from '../../constants/event';
import styles from './myAccount.module.scss';
import { type ReduxState } from '../../types';
import { signout } from '../../actions/signout';
import { useSelector } from 'react-redux';
import logger from '../../utils/logger';
import { useQueryClient } from '@tanstack/react-query';
import { setCookie } from '@/utils/cookie';
import { useRewardsActivity } from '@/features/rewards/api/getRewardsActivity';
import { setCanShowToastNotification } from '@/actions/app';
import { useDispatch } from '@/hooks/redux/useDispatch';
import { useMyProfile } from '@/features/myProfile/api/getMyProfile';
import { Text, View } from '@az/starc-ui';
import NextImage from '@/components/NextImage';
import { downArrow } from '../../constants/images/downArrow';
import { Salutation } from './Salutation';
import { MenuItems } from './menuItems';
import { SignInFlowTypeContext } from '../SignInFlowTypeContext';
import { SignInWave } from './SignInWave';
import { useFeatureFlag } from '@/features/globalConfig';

type MyAccountProps = {
  isFixedHeader?: boolean;
  handleClose?: () => void;
  setShowSignInAlert?: (show: boolean) => void;
};

export const MyAccount = ({ handleClose, isFixedHeader, setShowSignInAlert }: MyAccountProps) => {
  const myAccountRef = useRef<any | null>(null);
  const myAccountRefDiv = useRef<HTMLDivElement | null>(null);
  const locale = useLocale();
  const router = useRouter();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { data: rewardsData } = useRewardsActivity();
  const { userAuthenticated } = useSelector(({ appData }: ReduxState) => appData);
  const rewardsBalance = (rewardsData?.loyaltyAccount?.rewardBalance ?? 0).toFixed(2);
  const LOTTIE_ANIMATIONS_ENABLED = useFeatureFlag('LOTTIE_ANIMATIONS_ENABLED') === 'true';

  const [accountLinkHovered, setAccountLinkHovered] = useState(false);
  const [showErr, setShowErr] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [refocus, setRefocus] = useState(false);
  const [backDropClicked, setBackDropClicked] = useState(false);
  const { data: userData, isFetching: isFetchingProfile } = useMyProfile();
  const isUS = locale === countryCodes.enUS;

  const defaultStickyNavHeight = userAuthenticated ? (isUS ? 79 : 67) : 80;
  const [stickyNavBackdropOffset, setStickyNavBackdropOffset] = useState<number | undefined>(
    defaultStickyNavHeight
  );
  const { resetSignInFlowTypeContextFn } = useContext(SignInFlowTypeContext);

  const updateBackdropOffset = () => {
    const stickyNavHeight = document?.getElementById('fixedHeaderWrapper')?.clientHeight;
    setStickyNavBackdropOffset((stickyNavHeight ?? defaultStickyNavHeight) + 1);
  };

  useEffect(() => {
    const myAccountLink = document.getElementById('myAccountLink');
    if (myAccountLink) {
      myAccountLink.addEventListener('mouseenter', handleMouseEnter);
      myAccountLink.addEventListener('mouseleave', handleMouseLeave);
    }
    return () => {
      if (myAccountLink) {
        myAccountLink.removeEventListener('mouseenter', handleMouseEnter);
        myAccountLink.removeEventListener('mouseleave', handleMouseLeave);
      }
    };
  });

  const handleMouseEnter = () => setAccountLinkHovered(true);

  const handleMouseLeave = () => setAccountLinkHovered(false);

  const closeModal = useCallback(() => {
    setShowModal(false);
    setShowSignInAlert?.(true);

    if (handleClose) {
      handleClose();
    }
  }, [handleClose, setShowSignInAlert]);

  const handleMouseDown = useCallback(() => {
    if (!showModal || backDropClicked) {
      return;
    }
    setShowModal(false);
    setShowSignInAlert?.(true);
  }, [backDropClicked, showModal, setShowSignInAlert]);

  const escKeyDown = useCallback(
    (event: any) => {
      if (showModal) {
        event.stopImmediatePropagation();
      }
      const code = event.keyCode || event.which;
      if (code === eventConstants.escKeyCode && showModal) {
        closeModal();
        setRefocus(true);
      }
    },
    [closeModal, showModal]
  );

  const preventSpaceBarScrollingForMyAccount = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.code === eventConstants.spaceKeyTypeString) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    const accountRef = myAccountRef?.current;
    accountRef?.addEventListener('keypress', preventSpaceBarScrollingForMyAccount, false);
    return () => {
      accountRef?.removeEventListener('keypress', preventSpaceBarScrollingForMyAccount, false);
    };
  });

  useEffect(() => {
    const nonStickyHeader = document.getElementById('non-sticky-header');
    if (nonStickyHeader && showModal) {
      nonStickyHeader.addEventListener('click', () => setShowModal(false));
    }
    return () => {
      nonStickyHeader?.removeEventListener('click', () => setShowModal(false));
    };
  }, [showModal]);

  const handleClickOutside = useCallback(() => {
    const topHeaderWrapperElement = document.getElementById('topHeaderWrapper');
    const fixedHeaderWrapperElement = document.getElementById('fixedHeaderWrapper');
    const headerPromoElement = document.getElementById('header-promo');

    myAccountRef?.current?.addEventListener(
      'mouseleave',
      function () {
        topHeaderWrapperElement?.addEventListener('mousedown', handleMouseDown);
        fixedHeaderWrapperElement?.addEventListener('mousedown', handleMouseDown);
        headerPromoElement?.addEventListener('mousedown', handleMouseDown);
      },
      false
    );
    myAccountRef?.current?.addEventListener(
      'mouseover',
      function () {
        topHeaderWrapperElement?.removeEventListener('mousedown', handleMouseDown);
        fixedHeaderWrapperElement?.removeEventListener('mousedown', handleMouseDown);
        headerPromoElement?.removeEventListener('mousedown', handleMouseDown);
      },
      false
    );
  }, [handleMouseDown]);

  useEffect(() => {
    handleClickOutside();
    document.addEventListener('keydown', escKeyDown, false);
    return () => {
      document.removeEventListener('keydown', escKeyDown, false);
    };
  }, [escKeyDown, handleClickOutside, showModal]);

  useEffect(() => {
    if (refocus) {
      refocusMyAcctBtn();
    }
  }, [refocus, showModal]);

  const setAnalyticsVar = () => {
    if (!userAuthenticated) {
      setCookie('loginInteractionMethod', 'Header');
    }
  };

  const handleKeyPress = (event: any) => {
    const currentTarget = event.currentTarget;
    const myAccountCurrentTarget = myAccountRef.current;
    const isMyAccountPressed = currentTarget === myAccountCurrentTarget;
    const isEnterPressed = event.key === eventConstants.enterKeyType;
    const isTabPressed = event.key === eventConstants.tabKeyType;
    const isSpaceBarPressed = event.key === eventConstants.spaceKeyType;
    const dropDownItems = document.getElementById('dropdown-menu-items');
    const lastChildEl = dropDownItems?.lastElementChild?.lastElementChild;

    const lastMenuItemSelectedGuest =
      !userAuthenticated &&
      (dropDownItems?.lastElementChild === document.activeElement ||
        lastChildEl?.contains(document.activeElement));

    // [4] is the parent of the last selectable elements: "Need Help?" and "Sign Out"
    const lastMenuItemSelectedAuthenticated =
      userAuthenticated &&
      isTabPressed &&
      dropDownItems?.children[4]?.lastElementChild?.contains(document.activeElement);

    const closeDropdown =
      isTabPressed &&
      event.shiftKey === false &&
      (lastMenuItemSelectedGuest || lastMenuItemSelectedAuthenticated);

    if (isMyAccountPressed && (isEnterPressed || isSpaceBarPressed) && !showModal) {
      setShowModal(true);
      setShowSignInAlert?.(false);
      setAnalyticsVar();
    }

    if (closeDropdown) {
      setShowModal(false);
      setShowSignInAlert?.(true);
    }
  };

  const handleSignOut = async () => {
    if (userAuthenticated) {
      setShowLoading(true);
    }
    try {
      await dispatch(setCanShowToastNotification(false));
      await dispatch(signout(queryClient));
      resetSignInFlowTypeContextFn();
      setShowModal(false);
      setShowSignInAlert?.(true);
      setShowLoading(false);
      router.push('/');
    } catch (e) {
      setShowLoading(false);
      setShowErr(true);
      setShowModal(true);

      logger.error({
        meta: e,
        message: 'Error in Sign Out inside MyAccount',
      });
    }
    handleClose?.();
  };

  const toggleModal = () => {
    updateBackdropOffset();
    setShowModal(!showModal);
    setShowSignInAlert?.(showModal);
  };
  const handleBackDropClick = () => {
    setBackDropClicked(true);
    setShowModal(false);
    setShowSignInAlert?.(true);
  };

  const closeModalAndSignOut = () => {
    closeModal();
    handleSignOut();
  };

  const refocusMyAcctBtn = () => myAccountRefDiv.current?.focus();

  const showRewardsBalance = locale !== countryCodes.mx;

  return (
    <div
      className={styles.myAccountContainer}
      data-test="component-MyAccount"
      ref={myAccountRef}
      onKeyDown={handleKeyPress}
    >
      <Hidden only={['sm']}>
        <div
          ref={myAccountRefDiv}
          onClick={(e) => {
            setAnalyticsVar();
            const linkWasClicked = e.currentTarget.tagName === 'A';
            if (!linkWasClicked) {
              toggleModal();
            }
          }}
          data-test="toggle-button"
          aria-haspopup="true"
          aria-expanded={showModal}
          tabIndex={0}
          role="button"
        >
          {isFixedHeader ? (
            <div data-testid="stickyHeaderId" role="button">
              <View direction="row" align="center" padding={2} wrap={false}>
                <View.Item>
                  <View justify="center" align="center" direction="column" height="100%">
                    {!userAuthenticated ? (
                      <View.Item>
                        <View direction="row" align="end">
                          <View.Item>
                            <View
                              align="center"
                              justify="center"
                              className={cx(styles.userIconSticky)}
                              attributes={{ id: 'myAccountUserIconSticky' }}
                            >
                              <NextImage
                                src="/images/signin.svg"
                                alt=""
                                width={16.67}
                                height={16.67}
                              />
                            </View>
                          </View.Item>
                          <View.Item gapBefore={1}>
                            <View
                              padding={[1, 0]}
                              height="100%"
                              justify={rewardsBalance !== '0.00' ? 'center' : 'end'}
                            >
                              <View.Item>
                                <NextImage
                                  width={12}
                                  height={8}
                                  src={downArrow.src}
                                  alt={downArrow.alt}
                                  data-testid="at_arrow_sign_in_icon"
                                />
                              </View.Item>
                            </View>
                          </View.Item>
                        </View>
                      </View.Item>
                    ) : (
                      <View.Item>
                        <View height="24px" width="24px" align="center" justify="center">
                          <img
                            src="/images/avatar.svg"
                            alt=""
                            width={24}
                            height={24}
                            decoding="async"
                          />
                        </View>
                      </View.Item>
                    )}
                    {!userAuthenticated ? (
                      <View.Item attributes={{ 'data-test': 'noauth-content' }}>
                        <Text variant="subtitle-regular">
                          <Label label="mf_homepage_header_signin" />
                        </Text>
                      </View.Item>
                    ) : null}
                  </View>
                </View.Item>
                <View.Item>
                  <View padding={userAuthenticated ? [0, 2] : 0}>
                    {userAuthenticated ? (
                      <View.Item>
                        <View direction="column" attributes={{ id: 'at_user_name_icon' }}>
                          <View.Item
                            attributes={{
                              'data-testid': 'auth-content',
                              'data-test': 'auth-content',
                            }}
                          >
                            <Salutation
                              textVariant="small-body"
                              userFirstName={userData?.firstName}
                              isFetchingProfile={isFetchingProfile}
                            />
                          </View.Item>
                          {showRewardsBalance ? (
                            <>
                              <View height="2px" />
                              {rewardsBalance !== '0.00' ? (
                                <View.Item attributes={{ 'data-testid': 'at_user_rewards_link' }}>
                                  <Text
                                    className={styles.rewardsBalance}
                                    variant="subtitle-bold"
                                    attributes={{ style: { lineHeight: '20px' } }}
                                  >
                                    <Label label="label_MyAccountMyProfile_landing_Rewards" />
                                    {`: $${rewardsBalance}`}
                                  </Text>
                                </View.Item>
                              ) : (
                                <View.Item>
                                  <Text
                                    variant="subtitle-bold"
                                    attributes={{ style: { lineHeight: '20px' } }}
                                  >
                                    <Label label="label_myAccount_account" />
                                  </Text>
                                </View.Item>
                              )}
                            </>
                          ) : null}
                        </View>
                      </View.Item>
                    ) : null}
                  </View>
                </View.Item>
                {userAuthenticated ? (
                  <View.Item>
                    <View
                      padding={[1, 0]}
                      height="100%"
                      justify={rewardsBalance !== '0.00' ? 'center' : 'end'}
                    >
                      <View.Item>
                        <img
                          width={11}
                          height={6}
                          src={downArrow.src}
                          alt={downArrow.alt}
                          data-testid="at_arrow_sign_in_icon"
                          decoding="async"
                        />
                      </View.Item>
                    </View>
                  </View.Item>
                ) : null}
              </View>
            </div>
          ) : (
            <View direction="row" align="center" padding={[2, 2]}>
              <View.Item>
                {!userAuthenticated ? (
                  <>
                    <View
                      justify="center"
                      align="center"
                      direction="column"
                      height="100%"
                      padding={[0, 2]}
                    >
                      <View.Item>
                        <View
                          align="center"
                          justify="center"
                          className={cx(styles.userIcon, styles.userIconSticky)}
                          attributes={{ id: 'myAccountUserIcon' }}
                        >
                          {LOTTIE_ANIMATIONS_ENABLED && isUS ? (
                            <View.Item>
                              <SignInWave />
                            </View.Item>
                          ) : (
                            <img
                              src="/images/signin.svg"
                              alt=""
                              width={16.67}
                              height={18.33}
                              decoding="async"
                            />
                          )}
                          <View.Item
                            attributes={{ 'data-test': 'noauth-content' }}
                            className={styles.label}
                          >
                            <Text variant="subtitle-regular">
                              <Label label="mf_homepage_header_signin" />
                            </Text>
                          </View.Item>
                        </View>
                      </View.Item>
                    </View>
                  </>
                ) : (
                  <>
                    <View justify="center" align="center" direction="column" height="100%">
                      <View.Item>
                        <View height="24px" width="24px" align="center" justify="center">
                          <img
                            src="/images/avatar.svg"
                            alt=""
                            width={24}
                            height={24}
                            decoding="async"
                          />
                        </View>
                      </View.Item>
                    </View>
                  </>
                )}
              </View.Item>
              {userAuthenticated ? (
                <View.Item>
                  <View padding={[0, 2]}>
                    <View.Item>
                      <View direction="column" attributes={{ id: 'at_user_name_icon' }}>
                        <View.Item
                          attributes={{
                            'data-testid': 'auth-content',
                            'data-test': 'auth-content',
                          }}
                        >
                          <Salutation
                            textVariant="small-body"
                            userFirstName={userData?.firstName}
                            isFetchingProfile={isFetchingProfile}
                          />
                        </View.Item>
                        {showRewardsBalance ? (
                          <>
                            <View height="2px" />
                            {rewardsBalance !== '0.00' ? (
                              <View.Item attributes={{ 'data-testid': 'at_user_rewards_link' }}>
                                <Text
                                  className={styles.rewardsBalance}
                                  variant="subtitle-bold"
                                  attributes={{ style: { lineHeight: '20px' } }}
                                >
                                  <Label label="label_MyAccountMyProfile_landing_Rewards" />
                                  {`: $${rewardsBalance}`}
                                </Text>
                              </View.Item>
                            ) : (
                              <View.Item>
                                <Text
                                  variant="subtitle-bold"
                                  attributes={{ style: { lineHeight: '20px' } }}
                                >
                                  <Label label="label_myAccount_account" />
                                </Text>
                              </View.Item>
                            )}
                          </>
                        ) : null}
                      </View>
                    </View.Item>
                  </View>
                </View.Item>
              ) : null}
              <View.Item>
                <View
                  padding={[1, 0]}
                  height="100%"
                  justify={rewardsBalance !== '0.00' || !userAuthenticated ? 'center' : 'end'}
                >
                  <View.Item>
                    <img
                      width={userAuthenticated ? 11 : 12}
                      height={userAuthenticated ? 6 : 8}
                      className={styles.arrow}
                      src={downArrow.src}
                      alt={downArrow.alt}
                      data-testid="at_arrow_sign_in_icon"
                      decoding="async"
                    />
                  </View.Item>
                </View>
              </View.Item>
            </View>
          )}
        </div>
        {showModal ? (
          <div
            className={cx({
              [styles.popupArrowAuthenticated]: userAuthenticated,
              [styles.popupArrow]: !userAuthenticated,
              [styles.accountPopupArrowGray]: accountLinkHovered,
              [styles.accountPopupUserAuthenticated]: userAuthenticated,
              [styles.accountPopup]: !userAuthenticated,
              [locale !== countryCodes.mx ? styles.topHeaderOffset : styles.topHeaderOffsetMX]:
                userAuthenticated && !isFixedHeader,
              [locale !== countryCodes.mx ? styles.fixedHeaderOffset : styles.fixedHeaderOffsetMX]:
                userAuthenticated && isFixedHeader,
            })}
            data-testid="dropdown-menu-items"
            id="dropdown-menu-items"
          >
            <MenuItems
              userAuthenticated={userAuthenticated}
              userFirstName={userData?.firstName}
              userType={userData?.userType}
              rewardsBalance={rewardsBalance}
              closeModal={closeModal}
              closeModalAndSignOut={closeModalAndSignOut}
              showErr={showErr}
              refocusMyAcctBtn={refocusMyAcctBtn}
            />
          </div>
        ) : null}
        <Backdrop
          id="backdropComponent"
          open={showModal}
          onClick={handleBackDropClick}
          style={{
            transform: `translateY(${isFixedHeader ? stickyNavBackdropOffset : 0}px)`,
          }}
        />
      </Hidden>
      {/* TODO: check where this is used and remove if it's not   */}
      <Hidden only={['xl', 'lg', 'md']} implementation="js">
        <MenuItems
          userAuthenticated={userAuthenticated}
          userFirstName={userData?.firstName}
          userType={userData?.userType}
          rewardsBalance={rewardsBalance}
          closeModal={closeModal}
          closeModalAndSignOut={closeModalAndSignOut}
          showErr={showErr}
          refocusMyAcctBtn={refocusMyAcctBtn}
        />
      </Hidden>
      {showLoading ? <ProgressModal /> : null}
    </div>
  );
};
